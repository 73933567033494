.main {
  width: 100vw;
  max-width: 600px;
  height: 100vh;
  margin: 0 auto;
  overflow-y: scroll;
  position: relative;
}
.wrap {
  width: 100%;
}
.item {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #aaa;
}
.item:last-of-type {
  border-bottom: none;
}
.item img {
  width: 100px;
  height: 100px;
}
.item div {
  flex: 1;
  padding: 5px;
}

.loading {
  padding: 10px 10px 30px;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  color: salmon;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.loading img {
  width: 80px;
}